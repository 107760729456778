<template>
  <v-container>
    <v-layout row wrap class="mt-2">
      <v-flex xs12 sm6 md6 lg6>
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          :loading="loading"
          @click="backToProfile()"
        >
          <v-icon small left>fas fa-arrow-left</v-icon>
          <span class="">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm6 md6 lg6 class="text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          :loading="loading"
          @click="updateProfile"
        >
          <v-icon small left>save</v-icon>
          <span class="">{{ $t("save changes") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="my-4">
      <v-flex xs12 sm12 md12 lg12>
        <span class="text-h4 font-weight-black">{{ $t("edit profile") }}</span>
      </v-flex>
    </v-layout>
    <v-card class="mx-auto" tile>
      <v-card-title class="gradients white--text text-capitalize">
      </v-card-title>
      <v-container>
        <v-layout row wrap justify-space-around class="px-3">
          <v-flex xs12 sm3 md3 lg3>
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="user.avatar"></v-img>
            </v-avatar>
            <input type="file" @change="previewPicture" class="caption mt-2" />
          </v-flex>
          <v-flex xs12 sm12 md9 lg9>
            <v-layout row wrap class="mt-4">
              <v-flex xs12 sm4 md4 lg4 class="px-1">
                <v-text-field
                  v-model="user.names.first"
                  :color="$store.state.secondaryColor"
                  :label="$t('firstname')"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="px-1">
                <v-text-field
                  v-model="user.email"
                  :color="$store.state.secondaryColor"
                  :label="$t('email')"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm4 md4 lg4 class="px-1">
                <v-text-field
                  v-model="user.username"
                  :color="$store.state.secondaryColor"
                  :label="$t('username')"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card dark height="30">
        <v-card-text class="py-2">
          <v-progress-linear
            height="10"
            :v-model="progressValue"
            :buffer-value="progressValue"
            stream
            striped
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("data updated successfully") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("could not update details. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "firebase/app";
import db from "@/plugins/fb";
import jwt from "jsonwebtoken";

export default {
  data: () => ({
    user: {},
    loading: false,
    dialog: false,
    snackSuccess: false,
    progressValue: 10,
    snackError: false,
    file: "",
    imagePreview: "https://cdn.vuetifyjs.com/images/profiles/marcus.jpg",
  }),

  created() {
    //this.user = jwtdecode(this.$store.state.token, this.$store.state.accessKey);
    this.getUser();
  },

  methods: {
    previewPicture(event) {
      this.file = event;
      this.imagePreview = URL.createObjectURL(this.file);
    },

    getUser() {
      db.collection("rootAccess")
        .where("email", "==", this.$route.params.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.user = snaps.data();
            this.user.id = snaps.id;
          });
          this.loading = false;
        });
    },

    getProfile() {
      // TODO: get profile from firease
    },

    updateProfile() {
      if (this.file != "") {
        this.dialog = true;
        this.progressValue = 30;
        db.collection("rootAccess")
          .doc(this.user.id)
          .update(this.user)
          .then(() => {
            //allow the owner to update their picture
            this.uploadProfilePicture();
          })
          .catch(() => {
            this.dialog = false;
            this.snackError = true;
          });
      } else {
        this.dialog = true;
        this.progressValue = 30;
        db.collection("rootAccess")
          .doc(this.user.id)
          .update(this.user)
          .then(() => {
            this.progressValue = 50;
            this.progressValue = 70;
            this.progressValue = 100;

            this.dialog = false;

            this.snackSuccess = true;

            //set new jwt token data
            let key = this.$store.state.accessKey;
            let token = jwt.sign(this.detail, key);

            //keep token in store
            this.$store.commit("SET_TOKEN", token);
          })
          .catch(() => {
            this.dialog = false;
            this.snackError = true;
          });
      }
    },
    uploadProfilePicture() {
      this.progressValue = 50;
      this.progressValue = 70;

      let uploadTask = fb
        .storage()
        .ref("users/" + this.user.username)
        .put(this.file);

      uploadTask.on(
        "state_changed",
        () => {},
        () => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("rootAccess")
              .doc(this.user.id)
              .update({
                avatar: downloadLink,
              })
              .then(() => {
                //set new jwt token data
                let key = this.$store.state.accessKey;
                let token = jwt.sign(this.user, key);

                //keep token in store
                this.$store.commit("SET_TOKEN", token);

                this.loading = false;
                this.progressValue = 100;
                this.dialog = false;
                this.snackSuccess = true;
              })
              .catch(() => {
                this.snackError = true;
              });
          });
        }
      );
    },

    backToProfile() {
      window.history.back();
    },
  },
};
</script>

<style></style>
